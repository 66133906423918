<template>
  <div class="courseDetail">
    <div v-if="courseDetailType == 1" class="courseCard pageContent">
      <div class="gallery">
        <img v-if="courseDetailData" :src="courseDetailData.cover_image_url" class="courseImage"
             alt="">
      </div>
      <div class="property">
        <div class="title">
          <div class="detail-hd">
            {{courseDetailData.name}}
          </div>
          <div class="detail-agreement">
            <router-link to="/terms">
              《课程协议》
            </router-link>
          </div>
        </div>
        <div class="key">
          <div class="course-subject">
            <div class="key">科目选择</div>
            <div class="value">
              <span v-for="(item) in courseDetailData.attributes" :key="item.id" :value="item.id"
                    :class="item.id===activeCourse.id?'active':''"
                    @click="computedTeacherLevel(item)">{{item.name}}</span>
            </div>
          </div>
          <div class="teacher-level" v-if="courseDetailData.class_type===1">
            <div class="key">教师级别</div>
            <div class="value">
              <span v-for="item in teacherLevel.teacher_level" :key="item.id"
                    :class="item.id===activeTeacher.id?'active':''"
                    @click="computedLeastQty(item)">{{item.name}}</span>
            </div>
          </div>
          <div class="course-amount" v-if="courseDetailData.class_type===1">
            <div class="key">购买课时</div>
            <el-input-number v-if="courseDetailData.class_type===1" v-model="buyNum" size="mini" @change="handleChange"
                             :min="leastQty" :max="maxBuyNum"
                             label=""></el-input-number>
            <el-input-number v-else v-model="buyNum" size="mini" @change="handleChange" :min="1*1" :max="1*1"
                             label=""></el-input-number>
          </div>
        </div>
        <div class="action">
          <div class="course-price">
            <div class="key">课程价格</div>
            <div class="price" v-show="showPrice">￥<span>{{showPrice}}</span></div>
          </div>
          <div class="buy-course">
            <div class="btn-buy" @click="buyCourse">立即购买</div>
<!--            <div class="btn-basket" @click="addToCart">加入购物车</div>-->
            <div class="btn-service btn-basket"  @click="serviceBefore">立即咨询
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="personal pageContent">
      <div v-if="videoUrl === ''&&courseDesc.course" class="notBegin"
           :style="{'background-image':'url('+courseDesc.course.cover_image_url+')'}">
        <div class="tip" v-if="currentCourse.live_countdown>30000">
          <div>该课程还未开始</div>
          <br>
          <div v-if="currentCourse.time">({{currentCourse.time}})</div>
        </div>
        <div class="tip" v-else-if="currentCourse.live_countdown >0" :time="currentCourse.live_countdown">
          <div>该课程马上开始</div>
          <br>
          <div v-if="currentCourse.time">({{currentCourse.time}})</div>
          <div class="join-live">进入直播间</div>
        </div>
        <div class="tip" v-else-if="currentCourse.live_countdown === 0">
          <div>该课程已开始</div>
          <br>
          <router-link :to="{name:'live',params:{id: currentCourse.live_room_id}}" class="join-live">进入直播间</router-link>
        </div>
      </div>
      <div v-else class="galleryVideo">
        <t-c-player class="player-size"
                    @onPlayerPlay="onPlayerPlay"
                    @onPlayerPause="onPlayerPause"
                    :fid="currentVideoFid"></t-c-player>
      </div>
      <div class="course-list">
        <div class="item" v-for="(item,index) in courseDetailData" :key="index">
          <div class="item-title">{{item.title}}</div>
          <div class="item-course" v-for="(course, index) in item.children"
               :class="currentCourseId === course.id? 'active':''" @click="changeVideo(course)" :key="index">
            <span class="tag" :class="currentCourseId === course.id? 'active':''">{{course.mode.value}}</span>
            <div class="course-con">
              <div class="course-name" :class="currentCourseId === course.id? 'active':''">{{course.title}}</div>
              <div class="course-time" :class="currentCourseId === course.id? 'active':''">{{course.time}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="courseDetailType == 2&&courseDesc.course" class="bottom-title">{{courseDesc.course.title}}</div>
    <div class="courseRelated">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="课程简介" name="detail" v-if="courseDetailType == 1">
          <img style="width: 100%" :src="courseDetailData.introduction_image_url" alt="">
        </el-tab-pane>
        <el-tab-pane label="课程表" name="schedule" v-if="courseDetailData.class_type!==1">
          <ClassSchedule :classSchedule="classSchedule"></ClassSchedule>
        </el-tab-pane>
        <el-tab-pane label="课程资料" name="materials" v-if="courseDetailType == 2">
          <CourseMaterial :materials="CourseMaterialData"></CourseMaterial>
        </el-tab-pane>
<!--        <el-tab-pane label="评价" name="comment">-->
<!--          <Comment :isComment="courseDesc.is_comment" v-if="activeName==='comment'"></Comment>-->
<!--        </el-tab-pane>-->
        <el-tab-pane label="相关课程" name="relation">
          <RelationCourse :relationData="relationCourse"></RelationCourse>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="qr-code" v-show="isShowQrcode">
      <div class="close-btn iconfont icon-papa-close-small" @click="handleQrcode"></div>
      <div class="title">立即咨询</div>
      <img class="qr-image" :src="courseDetailData.we_chat_image_url" alt="">
      <div class="tip">微信扫码添加小助手，咨询课程</div>
    </div>
  </div>
</template>

<script>
  import CourseDetailJs from './CourseDetail.js'

  export default CourseDetailJs
</script>

<style lang="less" scoped>
  .courseDetail {
    width: 100%;
    margin-top: 17px;
    // position: absolute;
    top: 100px;
    left: 0;
    background: #fff;
    margin-bottom: 30px;

    .courseCard, & > .personal {
      .pageContent;
      margin: 0 auto;
      box-sizing: border-box;
      background: #fff;
      box-shadow: 0px 2px 6px 0px rgba(95, 95, 95, 0.07);
      display: flex;
      justify-content: space-between;

      .gallery {
        width: 62.5%;
        padding: 14px;
        box-sizing: border-box;

        img, video {
          width: 100%;
        }

        video {
          height: 405px;
        }

      }

      & > .notBegin {
        background: #000 no-repeat center;
        width: 75%;
        height: 405px;
        position: relative;

        & > .tip {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.7);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #fff;

          & > .join-live {
            width: 160px;
            height: 40px;
            color: #fff;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            background: #29D087;
            border-radius: 5px;
            cursor: pointer;

          }
        }
      }

      & > .galleryVideo {
        width: 75%;
        height: 405px;
        box-sizing: border-box;

        img, video {
          width: 100%;
          height: 100%;
        }
      }

      .property {
        width: 36%;
        box-shadow: 0px -7px 16px 0px rgba(95, 95, 95, 0.07);
        padding: 14px;
        display: flex;
        flex-direction: column;

        .title {
          margin-top: 6px;
          border-bottom: 1px solid #f2f2f2;
          text-align: left;
          min-height: 20%;

          .detail-hd {
            font-size: @fontSizeTitle;
            color: #333;
            font-weight: 800;
            margin-bottom: 15px;
          }

          .detail-agreement {
            cursor: pointer;

            & > a {
              color: #999;
              font-size: @fontSizeCon;
              padding: 14px 0 17px;
            }
          }
        }

        & > .key {
          box-sizing: border-box;
          border-bottom: 1px solid #f2f2f2;
          padding-bottom: 20px;
          min-height: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .course-subject, .teacher-level, .course-amount {
            padding-top: 14px;
            display: flex;
            font-size: @fontSizeCon;

            .key {
              color: #333;
              margin-right: 14px;
              line-height: 30px;
              min-width: 48px;
            }

            .value {
              width: calc(100% - 62px);

              span {
                border: 1px solid #d0d0d0;
                cursor: pointer;
                display: inline-block;
                padding: 5px 9px;
                border-radius: 3px;
                margin: 0 8px 5px 0;
                float: left;
              }

              .active {
                border: 1px solid @papaGreen;
                color: @papaGreen;
              }
            }
          }
        }

        & > .action {
          min-height: 30%;

          .course-price {
            padding-top: 14px;
            display: flex;
            font-size: @fontSizeCon;

            .key {
              color: #333;
              margin-right: 14px;
              line-height: 40px;
              min-width: 48px;
            }

            .price {
              color: @papaRed;

              span {
                font-size: @fontSizePrice;
              }
            }
          }

          .buy-course {
            display: flex;

            .btn-buy {
              .btnRed(170px, 36px, 2px);
              margin-right: 17px;
            }

            .btn-basket {
              cursor: pointer;
              width: 100px;
              height: 36px;
              line-height: 36px;
              color: #fe6f76;
              border: 1px solid @papaRed;
              border-radius: 2px;
              box-sizing: border-box;
            }

            & > .btn-service {
              margin-left: 17px;
            }
          }
        }

      }

      & > .course-list {
        width: 25%;
        height: 405px;
        overflow-y: auto;
        background: #272727;
        box-sizing: border-box;
        padding: 0px 16px;

        & > .item {
          color: #fff;
          text-align: left;
          border-bottom: 1px solid #333333;
          padding: 20px 0;

          & > .item-title {
            font-weight: bold;
            font-size: @fontSizeSubTitle;
            margin-bottom: 14px;
          }

          & > .item-course {
            display: flex;
            margin-bottom: 14px;
            cursor: pointer;

            & > .tag {
              min-width: 32px;
              height: 18px;
              border-radius: 3px;
              background: #4D4D4D;
              font-size: 12px;
              color: #e6e6e6;
              text-align: center;
              margin-right: 10px;
              margin-top: 4px;
            }

            & > .active {
              color: #fff;
              background: @papaGreen;
            }

            & > .course-con {
              display: flex;
              flex-direction: column;
              color: #ccc;
              font-size: 14px;

              & > .course-name {
                margin-bottom: 12px;
              }

              & > .active {
                color: @papaGreen !important;
              }
            }
          }

          & > .active {
            color: @papaGreen !important;
          }
        }

      }
    }

    .bottom-title {
      .pageContent;
      width: 100%;
      height: 80px;
      padding: 28px 22px;
      box-sizing: border-box;
      color: #fff;
      font-size: @fontSizePrice;
      background: #404040;
      text-align: left;
    }

    .courseRelated {
      .pageContent;
      margin-top: 20px;
      padding: 24px;
      background: #fff;
    }

    .qr-code {
      width: 400px;
      height: 360px;
      background: #fff;
      box-shadow: 0px 13px 46px 0px rgba(86, 86, 86, 0.05);
      border-radius: 5px;
      position: absolute;
      top: 350px;
      left: 50%;
      margin-top: -180px;
      margin-left: -200px;
      z-index: 10000;

      & > .close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 20px;
        cursor: pointer;
      }

      & > .title {
        margin-top: 36px;
        font-size: 24px;
        color: #333;
        font-weight: bold;
      }

      & > .qr-image {
        width: 180px;
        height: 180px;
        border-radius: 5px;
        margin: 16px 0 10px;
      }

      & > .tip {
        font-size: 18px;
        color: #333;
      }
    }
  }
</style>
