import * as Api from '@/api/course/course.js'
import ClassSchedule from '@/components/ClassSchedule/ClassSchedule.vue'
import RelationCourse from '@/components/RelationCourse/RelationCourse.vue'
import CourseMaterial from '@/components/CourseMaterial/CourseMaterial.vue'
import Comment from '@/components/Comment/Comment.vue'
import TCPlayer from '@/components/TCPlayer/TCPlayer.vue'
import store from '@/store'
import {
  courseAnalystic,
  courseWatch,
  courseWatchDuration,
} from '@/api/common/common'

import { userSign } from '@/api/live/live'
import { resolveAddress, translateTag } from '@/utils/utils'
import { ANALYTIC_TYPE } from '@/enums/API'
import { classCourse } from '@/api/course/course.js'

export default {
  components: {
    ClassSchedule,
    RelationCourse,
    Comment,
    CourseMaterial,
    TCPlayer,
  },
  data () {
    return {
      activeCourse: {},
      activeTeacher: {},
      activeName: 'detail',
      courseDetailData: [],
      classSchedule: [],
      teacherLevel: [],
      leastQty: 1,
      unitPrice: 1,
      relationCourse: [],
      maxBuyNum: 999,
      buyNum: 0,
      comment: [],
      commentPagination: {},
      videoUrl: '',
      currentCourseId: '',
      CourseMaterialData: [],
      currentCourse: {},
      isBuy: 1, // type= 1 未购买  type=2  购买后,
      courseDesc: {},
      playerOptions: {
        height: '360',
      },
      isShowQrcode: false,
      timeCount: 1,
      timeInterval: null,
      playFlag: true, // 是否统计点击播放
      intervalObj: null,
      videoDruation: 0,
      currentVideoFid: '',
    }
  },
  mounted () {
    if (this.$route.path.indexOf('user') > 0) {
      this.isBuy = 2
    }
    if (this.isBuy === 2) {
      this.getCourseDesc()
    }
    this.getCourseDetail()
    if (this.isBuy === 2) {
      this.activeName = 'schedule'
      this.handleClick({ name: 'schedule' })
      this.getUserLiveToken()
    }
    this.getIsEnroll()
  },
  activated () {
    this.getCourseData()
  },
  computed: {
    showPrice: function () {
      return this.buyNum * this.unitPrice
    },
    courseDetailType: function () {
      if (this.$route.path.indexOf('user') > 0) {
        return 2
      } else {
        return 1
      }
    },
    courseId () {
      return this.$route.params.id || ''
    },
    postPic () {
      if (this.courseDesc && this.courseDesc.course) {
        return this.courseDesc.course.cover_image_url
      } else {
        return ''
      }
    },
  },
  methods: {
    getCourseDetail () {
      console.log(98)
      if (this.courseDetailType == 1) {
        Api.courseDetail(
            '',
            ( res ) => {
              this.courseDetailData = res
              console.log(this.courseDetailData.attributes[ 0 ].id)
              this.teacherLevel = res.attributes[ 0 ]
              if (res.class_type === 1) {
                this.leastQty = res.attributes[ 0 ].teacher_level[ 0 ].least_sale_qty
                this.buyNum = res.attributes[ 0 ].teacher_level[ 0 ].least_sale_qty
                this.unitPrice = res.attributes[ 0 ].teacher_level[ 0 ].unit_price
                this.activeCourse = this.teacherLevel
                this.activeTeacher = this.teacherLevel.teacher_level[ 0 ]
              } else {
                // res.attributes.forEach(ele => {
                //     ele.id = ele.subject_id
                // })
                this.leastQty = res.attributes[ 0 ].least_sale_qty
                this.buyNum = res.attributes[ 0 ].teacher_level[ 0 ].least_sale_qty
                this.unitPrice = res.attributes[ 0 ].teacher_level[ 0 ].unit_price
                this.activeCourse = this.teacherLevel
                // this.activeTeacher = this.teacherLevel.teacher_level[0]
              }
            },
            '',
            this.courseId
        )
      } else {
        Api.classCourse(
            '',
            ( res ) => {
              this.courseDetailData = res
              this.videoUrl = res[ 0 ].children[ 0 ].video_url
              this.currentCourse = res[ 0 ].children[ 0 ]
              this.currentCourseId = res[ 0 ].children[ 0 ].id
              this.currentVideoFid = res[ 0 ].children[ 0 ].video_id
            },
            this.courseId
        )
      }
    },
    getCourseDesc () {
      Api.userCourseDesc(
          'get',
          '',
          ( res ) => {
            this.courseDesc = res
          },
          '',
          this.courseId
      )
    },
    handleChange ( item ) {
      this.buyNum = item
    },
    getIsEnroll () {
      Api.pastCourseDetail(
          'get',
          '',
          ( res ) => {
            if (res.is_enroll) {
              // this.btnName = '立即学习'
            } else {
              if (this.isBuy === 2) {
                this.$router.push({
                  name: 'courseDetail',
                  params: {
                    id: this.$route.params.id,
                  },
                })
              }
            }
          },
          this.courseId
      )
    },

    // tab 切换
    handleClick ( item ) {
      switch (item.name) {
        case 'schedule':
          Api.coursePlanApi(this.courseId, ( res ) => {
            this.classSchedule = res
          })
          break
        case 'relation':
          Api.relationCourseApi(this.courseId, ( res ) => {
            this.relationCourse = res
          })
          break
        case 'comment':
          break
        case 'materials':
          Api.courseMaterials(this.courseId, ( res ) => {
            this.CourseMaterialData = res
          })
      }
    },
    computedTeacherLevel ( item ) {
      this.teacherLevel = item
      this.activeCourse = item
      console.log(item)
      if (item.teacher_level) {
        this.leastQty = item.teacher_level[ 0 ].least_sale_qty
        this.buyNum = item.teacher_level[ 0 ].least_sale_qty
        this.unitPrice = item.teacher_level[ 0 ].unit_price
        this.activeTeacher = this.teacherLevel.teacher_level[ 0 ]
      } else {
        this.leastQty = item.least_sale_qty
        this.buyNum = item.least_sale_qty
        this.unitPrice = item.unit_price
      }
    },
    computedLeastQty ( item ) {
      this.activeTeacher = item
      this.leastQty = item.least_sale_qty
      this.buyNum = item.least_sale_qty
      this.unitPrice = item.unit_price
    },
    subComment ( data ) {
      let params = {
        course_id: this.courseId,
        star: data.star,
        content: data.content,
      }
      Api.subCourseCommentApi(params, () => {
      })
    },
    changeVideo ( item ) {
      if (this.intervalObj) {
        clearInterval(this.intervalObj)
        const lastSecond = Math.floor(this.$refs.childplayer.getCurrentTime())
        let params = {
          course_id: this.$route.params.id,
          course_schedule_id: this.currentCourseId,
          duration: this.videoDruation,
          last_second: lastSecond,
        }
        Api.courseWatchDuration(params, () => {
          this.videoDruation = 0
          this.intervalObj = null
        })
      }
      this.playFlag = true
      this.currentVideoFid = item.video_id
      this.videoUrl = item.video_url
      this.currentCourseId = item.id
      this.currentCourse = item
    },
    buyCourse () {
      if (this.courseDetailData.jump_url) {
        let obj = {
          link_url: this.courseDetailData.jump_url,
          method: 2,
        }
        location.href = resolveAddress(obj)
      } else {
        if (this.courseDetailData.guide_price === 0) {
          this.$router.push({
            path: this.$route.path,
            query: {
              type: 2, // 1 立即购买 2 加入购物车
              id: this.$route.params.id,
            },
          })
        } else {
          let selectProduct = [
            {
              buy_qty: this.buyNum,
              course: this.courseDetailData,
              course_attribute: this.activeCourse.id
                  ? this.activeCourse
                  : this.activeTeacher,
              course_subject: this.activeCourse,
              teacher_level: this.activeTeacher,
              id: this.courseDetailData.id,
            },
          ]
          sessionStorage.selectCourse = JSON.stringify(selectProduct)
          let products = selectProduct
          store.dispatch('updateSelectProduct', { products })
          this.$router.push({
            path: '/confirmCart',
            query: {
              type: 1, // 1 立即购买
            },
          })
        }
      }
    },
    addToCart () {
      if (this.courseDetailData.jump_url) {
        let obj = {
          link_url: this.courseDetailData.jump_url,
          method: 2,
        }
        location.href = resolveAddress(obj)
      } else {
        let product = {
          courseDetail: this.courseDetailData,
          chooseDetail: {
            teacherLevel: this.activeTeacher,
            subject: this.activeCourse,
            buyNum: this.buyNum,
          },
        }
        let params = {
          buy_qty: this.buyNum,
          course_id: this.courseId,
          course_subject_id: this.activeCourse.id,
          teacher_level_id: this.activeTeacher.id,
        }
        Api.shoppingCart('post', params, () => {
          store.dispatch('addProduct', { product })
        })
      }
    },
    serviceBefore () {
      store.dispatch('setGlobalMask', { status: true })
      this.isShowQrcode = true
      let params = {
        type: ANALYTIC_TYPE.CLICK,
      }
      Api.courseAnalystic(this.courseId, params, () => {
      })
    },
    handleQrcode () {
      this.isShowQrcode = false
      store.dispatch('setGlobalMask', { status: false })
    },
    onPlayerPlay () {
      let params = {
        course_id: parseInt(this.courseId),
        course_schedule_id: this.currentCourseId,
      }
      if (this.playFlag) {
        console.log(params)
        courseWatch(params, () => {
          console.log('统计成功')
          this.playFlag = false
        })
      }
      this.intervalObj = setInterval(() => {
        this.videoDruation += 1
        console.log(this.videoDruation)
      }, 1000)
    },
    onPlayerPause () {
      clearInterval(this.intervalObj)
    },
    // get im token
    getUserLiveToken () {
      userSign(( res ) => {
        sessionStorage.userSign = res.user_sign
      })
    },
  },
  beforeRouteEnter ( to, from, next ) {
    next(( vm ) => {
      vm.timeCount = 0
      vm.timeInterval = setInterval(() => {
        vm.timeCount++
      }, 1000)
    })
  },
  beforeRouteLeave ( to, from, next ) {
    clearInterval(this.timeInterval)
    let params = {
      type: ANALYTIC_TYPE.EXIT,
      duration: this.timeCount,
    }
    //  小于零表示是从首页点击进入的  需要统计  大于零表示从我的课程进入不需要统计
    if (this.$route.path.indexOf('user') < 0) {
      Api.courseAnalystic(this.courseId, params, () => {
      })
    } else {
      if (this.intervalObj) {
        clearInterval(this.intervalObj)
        let params = {
          course_schedule_id: this.currentCourseId,
          duration: this.videoDruation,
        }
        Api.courseAnalystic(this.$route.params.id, params, () => {
          this.videoDruation = 0
          this.intervalObj = null
        })
      }
    }
    next()
  },

  watch: {
    $route ( newVal, oldVal ) {
      if (newVal !== oldVal) {
        if (this.$route.path.indexOf('user') > 0) {
          this.isBuy = 2
        }
        this.getCourseDetail()
      }
    },
  },
}
