<template>
  <div class="course-material">
    <div v-if="materials.length>0" class="item-box">
      <div class="item" v-for="item in materials" :key="item.description">
        <div v-if="item.type==1">
          <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              :content="item.description">
            <div class="rules" slot="reference">{{item.name}}
            </div>
          </el-popover>
        </div>
        <div v-else @click="jumpPage(item.description)">
          {{item.name}}
        </div>
      </div>
    </div>
    <NoContent v-else :data="noContentData"></NoContent>
  </div>
</template>

<script>
  import NoContent from '@/components/NoContent/NoContent.vue'

  export default {
    components: {
      NoContent
    },
    name: 'CourseMaterial',
    props: {
      materials: {
        type: Array,
        default: function () {
          return []
        }
      }
    },
    data () {
      return {
        noContentData: {
          pic: 'evaluation_pic',
          tip: '没有课程资料'
        }
      }
    },
    methods: {
      jumpPage (src) {
        window.open(src)
      }
    }
  }
</script>

<style lang="less" scoped>
  .course-material {
    & > .item-box {
      & > .item {
        height: 48px;
        line-height: 48px;
        text-align: left;
        padding-left: 12px;
        border-bottom: 1px solid #e6e6e6;
        cursor: pointer;
      }
    }

    .item:hover {
      background: #F4FDF8;
    }
  }
</style>
